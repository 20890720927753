<h1 mat-dialog-title>Update ETA For {{ data.title }}</h1>
<mat-dialog-content
	style="
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		max-width: 600px;
		padding: 1rem;
		gap: 0 1rem;
	"
	[formGroup]="form">
	<mat-form-field
		class="example-full-width"
		appearance="outline"
		floatLabel="always">
		<mat-label>Choose a date</mat-label>
		<input matInput [matDatepicker]="picker" formControlName="eta" />
		<mat-hint>DD/MM/YYYY</mat-hint>
		<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end">
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		(click)="save()"
		[disabled]="form.invalid || form.pristine || form.disabled"
		type="button"
		cdkFocusInitial>
		Update
	</button>
</mat-dialog-actions>
