import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	type OnInit,
} from '@angular/core';

@Component({
	selector: 'app-strike-line',
	templateUrl: './strike-line.component.html',
	styleUrls: ['./strike-line.component.css'],
})
export class StrikeLineComponent implements AfterContentInit {
	@Input() isStriked: boolean = false;
	isReady: boolean = false;

	private initialized = false;

	ngAfterContentInit() {
		// Option 1: After content is initialized
		requestAnimationFrame(() => {
			//this.isReady = true;
		});
	}

	ngAfterViewChecked() {
		// Option 2: After view is checked
		if (!this.initialized) {
			this.initialized = true;
			setTimeout(() => {
				requestAnimationFrame(() => {
					this.isReady = true;
				});
			}, 500);
		}
	}
}
