import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export interface ScrollState {
	position: number;
	direction: 'up' | 'down';
	progress: number;
	isAtTop: boolean;
	isAtBottom: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class ScrollService implements OnDestroy {
	private lastPosition = 0;
	private maxScroll = 0;

	// Main scroll state subject
	private scrollState = new BehaviorSubject<ScrollState>({
		position: 0,
		direction: 'down',
		progress: 0,
		isAtTop: true,
		isAtBottom: false,
	});

	// Observable streams
	readonly scrollState$ = this.scrollState.asObservable();

	// Convenience observables for specific properties
	readonly position$ = this.scrollState$.pipe(
		map((state) => state.position),
		distinctUntilChanged()
	);

	readonly direction$ = this.scrollState$.pipe(
		map((state) => state.direction),
		distinctUntilChanged()
	);

	readonly progress$ = this.scrollState$.pipe(
		map((state) => state.progress),
		distinctUntilChanged()
	);

	readonly isAtTop$ = this.scrollState$.pipe(
		map((state) => state.isAtTop),
		distinctUntilChanged()
	);

	readonly isAtBottom$ = this.scrollState$.pipe(
		map((state) => state.isAtBottom),
		distinctUntilChanged()
	);

	updateScrollPosition(
		position: number,
		containerHeight: number,
		scrollHeight: number
	) {
		this.maxScroll = scrollHeight - containerHeight;

		// Calculate new state
		const newState: ScrollState = {
			position,
			direction: position > this.lastPosition ? 'down' : 'up',
			progress: this.calculateProgress(position),
			isAtTop: position <= 0,
			isAtBottom: Math.ceil(position) >= Math.floor(this.maxScroll),
		};

		// Update last position for next comparison
		this.lastPosition = position;

		// Emit new state
		this.scrollState.next(newState);
	}

	getScrollState(): ScrollState {
		return this.scrollState.getValue();
	}

	private calculateProgress(position: number): number {
		if (this.maxScroll === 0) return 0;
		const progress = (position / this.maxScroll) * 100;
		return Math.min(Math.max(progress, 0), 100); // Clamp between 0 and 100
	}

	/**
	 * Get an observable that emits true when passing a specific scroll threshold
	 * @param threshold Percentage of scroll progress (0-100)
	 */
	getThresholdPassed(threshold: number): Observable<boolean> {
		return this.progress$.pipe(
			map((progress) => progress >= threshold),
			distinctUntilChanged()
		);
	}

	/**
	 * Reset the scroll service state
	 */
	reset() {
		this.lastPosition = 0;
		this.maxScroll = 0;
		this.scrollState.next({
			position: 0,
			direction: 'down',
			progress: 0,
			isAtTop: true,
			isAtBottom: false,
		});
	}

	ngOnDestroy() {
		this.scrollState.complete();
	}
}
