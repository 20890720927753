import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs';
import { AddPersonDialogComponent } from 'src/app/form-fields/people-form/add-person-dialog/add-person-dialog.component';
import { PeopleFormComponent } from 'src/app/form-fields/people-form/people-form.component';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import { PeopleBackendService } from 'src/app/services/people-backend.service';
import {
	PropertiesBackendService,
	Property,
} from 'src/app/services/properties-backend.service';
import {
	Request,
	RequestsBackendService,
} from 'src/app/services/requests-backend.service';
import {
	TaskStatus,
	TaskType,
	Task,
	TaskBackendService,
	TaskResult,
} from 'src/app/services/task-backend.service';
import { TitleService } from 'src/app/services/title.service';
import { userType } from 'src/app/setup/httpTypes';
import { environment } from 'src/environments/environment';
import { SendOfferRequestComponent } from './SendOfferRequest/SendOfferRequest.component';
import { OfferBackendService } from 'src/app/services/offer-backend.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
	selector: 'app-property',
	templateUrl: './property.component.html',
	styleUrls: ['./property.component.css'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ height: 0, opacity: 0, overflow: 'hidden' }),
				animate('300ms ease-out', style({ height: '*', opacity: 1 })),
			]),
			transition(':leave', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate('300ms ease-in', style({ height: 0, opacity: 0 })),
			]),
		]),
		trigger('hideInOutAnimation', [
			transition('true => false', [
				style({ height: 0, opacity: 0, overflow: 'hidden' }),
				animate('300ms ease-out', style({ height: '*', opacity: 1 })),
			]),
			transition('false => true', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate('300ms ease-in', style({ height: 0, opacity: 0 })),
			]),
			state('true', style({ height: 0, opacity: 0, overflow: 'hidden' })),
			state('false', style({ height: '*', opacity: 1 })),
		]),
	],
})
export class PropertyComponent implements OnInit, OnDestroy {
	$prams: any;
	propertyId = '';
	property: Partial<Property> = {};
	loading = true;
	taskList: any[] = [];
	nicknames: { name: string; cats: string[] }[] = [];
	loadingRequests = false;

	@ConfirmExitFrom() addressFormGroup = new FormGroup({
		nickName: new FormControl(''),
		address: new FormControl({ value: '', disabled: true }),
		postCode: new FormControl({ value: '', disabled: true }),
		UPRN: new FormControl({ value: '', disabled: true }),
	});

	requests: Request[] = [];

	userType = userType;

	requestsOpen = false;
	milestonesOpen = true;
	propertyInfoOpen = true;
	canEdit = false;
	userEmail = '';

	@ViewChildren(PeopleFormComponent)
	peopleComponents!: QueryList<PeopleFormComponent>;

	offset = 0;
	opacity = 1;
	private scrollSubscription?: Subscription;

	constructor(
		public PropertiesBackend: PropertiesBackendService,
		public AuthService: AuthService,
		public Router: Router,
		public route: ActivatedRoute,
		public requestsBackend: RequestsBackendService,
		public taskBackend: TaskBackendService,
		public confirmDialog: ConfirmDialogService,
		public titleService: TitleService,
		public PeopleBackend: PeopleBackendService,
		public OffersBackend: OfferBackendService,
		public Dialog: MatDialog,
		public cd: ChangeDetectorRef,
		public snackbar: MatSnackBar,
		public scroll: ScrollService
	) {}

	async ngOnInit() {
		this.scrollSubscription = this.scroll.scrollState$.subscribe((position) => {
			this.offset = position.position * 0.3; // Adjust this value to control parallax speed
			this.opacity = 1 - position.position / 700; // Adjust this value to control fade speed
			if (this.opacity < 0) this.opacity = 0;
			if (this.opacity > 1) this.opacity = 1;
		});
		this.titleService.title = 'Property';
		this.$prams = this.route.params.subscribe(async (params) => {
			this.propertyId = params['propertyId'];
			this.addressFormGroup = new FormGroup({
				nickName: new FormControl(''),
				address: new FormControl({ value: '', disabled: true }),
				postCode: new FormControl({ value: '', disabled: true }),
				UPRN: new FormControl({ value: '', disabled: true }),
			});
			this.getProperty();
			let r = await this.PropertiesBackend.getNicknames(this.propertyId);
			this.nicknames = r.data;

			this.titleService.title = this.property?.nickName || 'Property';
			this.cd.detectChanges();
		});
		if (this.AuthService.spoofAs && this.AuthService.spoofAs != '') {
			this.userEmail = this.AuthService.spoofAs;
		} else {
			this.AuthService.getUser().then((user) => {
				this.userEmail = user?.attributes?.email;
			});
		}
	}

	ngOnDestroy(): void {
		if (this.$prams) this.$prams.unsubscribe();
		if (this.scrollSubscription) this.scrollSubscription.unsubscribe();
	}

	async getProperty() {
		this.loading = true;
		let r = await this.PropertiesBackend.getOne(this.propertyId);
		this.canEdit = r.canEdit;
		this.property = r.data;

		this.addressFormGroup.patchValue({
			nickName: this.property.nickName,
			address: this.property.address || '',
			postCode: this.property.postCode,
			UPRN: this.property.UPRN,
		});
		this.addressFormGroup.disable();
		if (this.canEdit) this.addressFormGroup.get('nickName')?.enable();

		await this.getTasks();

		this.loading = false;

		await this.getRequests();

		if (this.property?.chainId) {
			let peopleAdded = false;
			let missingPeopleR = await this.PeopleBackend.missingPersons(
				this.property.chainId
			);
			let missingPeople = missingPeopleR.data;
			while (missingPeople.length > 0) {
				let dr = this.Dialog.open(AddPersonDialogComponent, {
					data: {
						title: `The Missing ${missingPeople[0].userType}`,
						description: `${missingPeople[0].propertyNickname} (${missingPeople[0].propertyAddress}) is missing a ${missingPeople[0].userType} please add them now.`,
						chainId: this.property.chainId,
						propertyId: missingPeople[0].propertyId,
						userType: missingPeople[0].userType,
					},
				});
				let p = await lastValueFrom(dr.afterClosed());
				if (p) {
					missingPeopleR = await this.PeopleBackend.missingPersons(
						this.property.chainId
					);
					missingPeople = missingPeopleR.data;
					peopleAdded = true;
				} else {
					missingPeople.shift();
				}
			}
			if (peopleAdded)
				for (const peopleComponent of this.peopleComponents.toArray()) {
					await peopleComponent.getPeople();
				}
		}

		//this.agentsFormGroup.setControl('agents', new FormArray(this.property.agents.map((agent:any) => this.agentFormControl(agent))))
	}

	async getRequests() {
		this.loadingRequests = true;
		let r = await this.requestsBackend.getList({
			chainId: this.property.chainId,
			propertyId: this.propertyId,
		});
		this.requests = r.data;
		if (this.requests.length > 0) this.requestsOpen = true;
		this.loadingRequests = false;
	}

	async saveAddressForm() {
		if (this.addressFormGroup.dirty) {
			let body: any = {};
			this.loading = true;
			this.addressFormGroup.disable();

			if (this.addressFormGroup.controls.nickName.dirty) {
				body.nickName = this.addressFormGroup.controls.nickName.value;
			}

			let r = await this.PropertiesBackend.patch(this.propertyId, body);

			this.property = r.data;

			this.addressFormGroup.patchValue({
				nickName: this.property.nickName,
				address: this.property.address || '',
				postCode: this.property.postCode,
				UPRN: this.property.UPRN,
			});
			this.titleService.title = this.property?.nickName || 'Property';
			this.addressFormGroup.disable();
			if (this.canEdit) this.addressFormGroup.get('nickName')?.enable();
			this.loading = false;
		}
	}

	async getTasks() {
		let r = await this.taskBackend.getList({
			chainId: this.property?.chainId,
			propertyId: this.propertyId,
			taskType: TaskType.milestone,
			children: true,
		});

		let taskList = r.data;

		// taskList.sort((a: Task, b: Task) => {
		// 	if ((a?.estimatedCompletionDate || 0) < (b?.estimatedCompletionDate || 0))
		// 		return -1;
		// 	if ((b?.estimatedCompletionDate || 0) < (a?.estimatedCompletionDate || 0))
		// 		return 1;

		// 	if ((a?.dateCreated || 0) < (b?.dateCreated || 0)) return -1;
		// 	if ((b?.dateCreated || 0) < (a?.dateCreated || 0)) return 1;

		// 	if ((a?.dateCompleted || 0) < (b?.dateCompleted || 0)) return -1;
		// 	if ((b?.dateCompleted || 0) < (a?.dateCompleted || 0)) return 1;
		// 	return 0;
		// });

		this.taskList = taskList;
	}

	async delete() {
		if (!this.canEdit) return;
		let confirm = await this.confirmDialog.confirm({
			title: 'Delete Property',
			message: `Are you sure you want to delete ${this.property.nickName} - ${
				this.property.address || ''
			}?`,
			confirmText: 'Delete',
		});
		if (!confirm) return;
		let r = await this.PropertiesBackend.delete(this.propertyId);
		this.Router.navigate(['/chain', this.property.chainId]);
	}

	sendOfferRequest() {
		let r = this.Dialog.open(SendOfferRequestComponent, {});

		let r$ = r.afterClosed().subscribe(async (result) => {
			if (result) {
				let { data } = await this.OffersBackend.put(this.propertyId, result);

				window.location.href = data;
			}
			r$.unsubscribe();
			await this.getProperty();
		});
	}

	userIsAgent() {
		return (
			this.property.persons?.some(
				(p) => p.email == this.userEmail && p.userType == userType.sellersAgent
			) || false
		);
	}

	userIsSeller() {
		return this.property.sellerPersons?.some((p) => p.email == this.userEmail);
	}

	userIsBuyer() {
		return this.property.buyerPersons?.some((p) => p.email == this.userEmail);
	}

	userIsOnProperty() {
		if (this.userIsBuyer() || this.userIsSeller()) return true;
		return this.property.persons?.some((p) => p.email == this.userEmail);
	}

	memorandumOfSaleCompleted() {
		return this.property?.tasks?.some(
			(t) => t.result == TaskResult.success && t.title == 'Memorandum of Sale'
		);
	}

	openDocument(doc: {
		id: string;
		name: string;
		date: number;
		type: string;
		taskId: string;
	}) {
		this.taskBackend.getDocumentDownloadUrl(doc.taskId, doc.id).then((r) => {
			let link = document.createElement('a');
			document.body.appendChild(link);
			link.download = doc.name;
			link.href =
				r.data +
				`&&response-content-disposition=attachment;filename=${doc.name}`;
			link.click();
			document.body.removeChild(link);
		});
	}

	openMaterialInfo() {
		this.Router.navigate(['/material-information', this.propertyId]);
	}

	async copyToClipboardMaterialInfoURL() {
		await navigator.clipboard.writeText(
			environment.rootUrl +
				'/material-information/' +
				this.propertyId +
				'?hideSide=true'
		);
		this.snackbar.open(
			'Material Information URL copied to clipboard',
			'Close',
			{
				duration: 2000,
			}
		);
	}

	editMaterialInfo() {
		this.Router.navigate(['/material-information', this.propertyId, 'setup']);
	}

	_showSnaPic = false;
	get showSanPic() {
		if (this.property?.pic && this.property?.sanPic) return this._showSnaPic;
		if (!this.property?.pic && this.property?.sanPic) return true;
		return false;
	}

	set showSanPic(value: boolean) {
		this._showSnaPic = value;
	}

	toggleSanPic() {
		this.showSanPic = !this.showSanPic;
	}

	option = false;
}
