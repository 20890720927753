import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	type OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-update-eta-model',
	templateUrl: './update-eta-model.component.html',
	styleUrls: ['./update-eta-model.component.css'],
})
export class UpdateEtaModelComponent implements OnInit {
	form = this.fb.group({
		eta: [this.data.eta, Validators.required],
	});
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			eta: Date;
			title: string;
		},
		public dialogRef: MatDialogRef<UpdateEtaModelComponent>,
		public fb: FormBuilder
	) {}
	ngOnInit(): void {}

	save() {
		this.dialogRef.close(this.form.value.eta);
	}
}
