import {
	NgModule,
	CUSTOM_ELEMENTS_SCHEMA,
	NO_ERRORS_SCHEMA,
	ErrorHandler,
	Injectable,
	Injector,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
// @ts-ignore
import { Amplify } from 'aws-amplify';
import { awsconfig } from './aws-exports';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { SetupComponent } from './setup/setup.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterInterceptor } from './services/auth-inter.interceptor';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TAndCSComponent } from './setup/t-and-c-s/t-and-c-s.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { ChainsComponent } from './chains/chains.component';
import { LogoComponent } from './logo/logo.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChainComponent } from './chains/chain/chain.component';
import { PropertiesComponent } from './properties/properties.component';
import { PropertyComponent } from './properties/property/property.component';
import { ChainNoSelectionComponent } from './chains/chain/chain-no-selection/chain-no-selection.component';
import { environment } from 'src/environments/environment';
import { BuyerSellerComponent } from './buyer-seller/buyer-seller.component';
import { MatDividerModule } from '@angular/material/divider';
import { TaskListsComponent } from './task-lists/task-lists.component';
import { NewBuyerSellerComponent } from './buyer-seller/new-buyer-seller/new-buyer-seller.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TaskComponent } from './task-lists/task/task.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NewPropertyComponent } from './properties/new-property/new-property.component';
import { DateVotingComponent } from './chains/chain/chain-no-selection/date-voting/date-voting.component';
import { AddressComponent } from './form-fields/address/address.component';
import { BuyerSellerSetupComponent } from './setup/buyer-seller-setup/buyer-seller-setup.component';
import { PeopleComponent } from './form-fields/people/people.component';
import { SellingAgentSetupComponent } from './setup/selling-agent-setup/selling-agent-setup.component';
import { MortgageBrokerSetupComponent } from './setup/mortgage-broker-setup/mortgage-broker-setup.component';
import { TasksComponent } from './tasks/tasks.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { RequestsComponent } from './requests/requests.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SettingsComponent } from './settings/settings.component';
import { ConfirmDialogComponent } from './form-fields/confirm-dialog/confirm-dialog.component';
import { RequestListComponent } from './requests/request-list/request-list.component';
import { PeopleFormComponent } from './form-fields/people-form/people-form.component';
import { LoadingTombstoneComponent } from './form-fields/loading-tombstone/loading-tombstone.component';
import { LineProgressComponent } from './form-fields/line-progress/line-progress.component';
import { MoneyInputDirective } from './form-fields/money-input.directive';
import { SpeechTextComponent } from './form-fields/speech-text/speech-text.component';
import { IssuesComponent } from './task-lists/task/issues/issues.component';
import { IssueComponent } from './task-lists/task/issues/issue/issue.component';
import { DropFilesDirective } from './form-fields/drop-files.directive';
import { DocumentsInputComponent } from './form-fields/documents-input/documents-input.component';
import { SelectFileButtonComponent } from './form-fields/select-file-button/select-file-button.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { UploadProgressComponent } from './form-fields/upload-progress/upload-progress.component';
import { DocumentsFormComponent } from './form-fields/documents-form/documents-form.component';
import { LoadingDotsComponent } from './form-fields/loading-dots/loading-dots.component';
import { ConveyancerComponent } from './setup/conveyancer/conveyancer.component';
import { MarkdownModule } from 'ngx-markdown';
import { TaskItemComponent } from './task-lists/task-item/task-item.component';
import { SwiperComponent } from './form-fields/swiper/swiper.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { ViewersComponent } from './task-lists/task/viewers/viewers.component';
import { MatTableModule } from '@angular/material/table';
import { BugsDialogComponent } from './form-fields/bugs-dialog/bugs-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { FAQComponent } from './faq/faq.component';
import { RouteMeComponent } from './login/route-me/route-me.component';
import { NewRequestDialogComponent } from './requests/new-request-dialog/new-request-dialog.component';
import { BugsService } from './services/bugs.service';
import { lastValueFrom } from 'rxjs';
import { NotificationFieldComponent } from './form-fields/notification-field/notification-field.component';
import { InquiriesComponent } from './task-lists/task/inquiries/inquiries.component';
import { InquiryComponent } from './task-lists/task/inquiries/inquiry/inquiry.component';
import { PayComponent } from './pay/pay.component';
import { NewDateDialogComponent } from './chains/chain/chain-no-selection/date-voting/new-date-dialog/new-date-dialog.component';
import { EditPersonDialogComponent } from './form-fields/people-form/edit-person-dialog/edit-person-dialog.component';
import { AddPersonDialogComponent } from './form-fields/people-form/add-person-dialog/add-person-dialog.component';
import { NewInquiryComponent } from './task-lists/task/inquiries/new-inquiry/new-inquiry.component';
import { LandingBuyerSellerComponent } from './landing-buyer-seller/landing-buyer-seller.component';
import { LandingOtherPartyComponent } from './landing-other-party/landing-other-party.component';
import { LandingSwitchComponent } from './landing-switch/landing-switch.component';
import { InboxComponent } from './inbox/inbox.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { EmailComponent } from './inbox/email/email.component';
import { AdminInboxComponent } from './admin-inbox/admin-inbox.component';
import { SortEmailComponent } from './admin-inbox/sort-email/sort-email.component';
import { TemplatePreferencesComponent } from './settings/templatePrefrences/templatePreferences.component';
import { OfferComponent } from './offer/offer.component';
import { GroupTitleComponent } from './form-fields/group-title/group-title.component';
import { SignatureComponent } from './form-fields/signature/signature.component';
import { OfferSubmittedComponent } from './offer/offer-submitted/offer-submitted.component';
import { SendOfferRequestComponent } from './properties/property/SendOfferRequest/SendOfferRequest.component';
import { SafePipe } from './services/task-backend.service';
import { CCSearchesComponent } from './task-lists/task/CC_Searches/CC_Searches.component';
import { MaterialInformationComponent } from './materialInformation/materialInformation.component';
import { MaterialFormComponent } from './materialInformation/materialForm/materialForm.component';
import { EditSpaceComponent } from './materialInformation/materialForm/edit-space/edit-space.component';
import { EditWorksComponent } from './materialInformation/materialForm/edit-works/edit-works.component';
import { EditHeatComponent } from './materialInformation/materialForm/edit-heat/edit-heat.component';
import { EditParkingSpotComponent } from './materialInformation/materialForm/editParkingSpot/editParkingSpot.component';
import { EditBikeStorageComponent } from './materialInformation/materialForm/Edit-Bike-Storage/Edit-Bike-Storage.component';
import { EditBuildingIssueComponent } from './materialInformation/materialForm/edit-building-issue/edit-building-issue.component';
import {
	CapFirstLettersPipe,
	StringListPipe,
} from './form-fields/string-list.pipe';
import { MaterialDocumentsFormComponent } from './materialInformation/materialForm/material-documents-form/material-documents-form.component';
import { DocsViewerComponent } from './materialInformation/docs-viewer/docs-viewer.component';
import { LocalPhoneNumberDirective } from './form-fields/localPhoneNumber.directive';
import { AMLComponent } from './AML/AML.component';
import { NewAMLDialog } from './AML/newAML/newAML.dialog';
import { AMLpermissionsComponent } from './AML/AMLpermissions/AMLpermissions.component';
import { AMLReportComponent } from './AML/AML-Report/AML-Report.component';
import { TaskAMLComponent } from './task-lists/task/task-AML/task-AML.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';
import { StrikeLineComponent } from './form-fields/strike-line/strike-line.component';
import { PropertyPostLinksComponent } from './properties/property/property-post-links/property-post-links.component';
import { UpdateEtaModelComponent } from './task-lists/task-item/update-eta-model/update-eta-model.component';

Amplify.configure(awsconfig);

declare function cwr(operation: string, payload: any): void;

(window as any).errors = [];
@Injectable()
export class CwrErrorHandler implements ErrorHandler {
	//constructor(public bugService: BugsService, private _snackBar: MatSnackBar) {}
	constructor(public injector: Injector) {}
	handleError(error: Error) {
		if ((window as any)?.errors?.length > 5) {
			(window as any)?.errors?.pop();
		}
		(window as any).errors.unshift(error);
		cwr('recordError', {
			error: error.message.toString(),
			stack: error.stack?.toString(),
		});
		try {
			const _snackBar = this.injector.get(MatSnackBar);
			const bugService = this.injector.get(BugsService);

			let sb = _snackBar.open(`An Error Has Occurred!`, 'Report Bug', {
				duration: 1000 * 10,
			});

			lastValueFrom(sb.onAction()).then(() => {
				//this.router.navigate(['/contact']);
				bugService.newBug();
			});
		} catch (error) {}
	}
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		HomeComponent,
		SetupComponent,
		TAndCSComponent,
		ChainsComponent,
		LogoComponent,
		ChainComponent,
		PropertiesComponent,
		PropertyComponent,
		ChainNoSelectionComponent,
		BuyerSellerComponent,
		TaskListsComponent,
		NewBuyerSellerComponent,
		TaskComponent,
		NewPropertyComponent,
		DateVotingComponent,
		AddressComponent,
		BuyerSellerSetupComponent,
		PeopleComponent,
		SellingAgentSetupComponent,
		MortgageBrokerSetupComponent,
		TasksComponent,
		AboutComponent,
		ContactComponent,
		RequestsComponent,
		PageNotFoundComponent,
		SettingsComponent,
		ConfirmDialogComponent,
		RequestListComponent,
		PeopleFormComponent,
		LoadingTombstoneComponent,
		LineProgressComponent,
		MoneyInputDirective,
		SpeechTextComponent,
		IssuesComponent,
		IssueComponent,
		DropFilesDirective,
		DocumentsInputComponent,
		SelectFileButtonComponent,
		UploadProgressComponent,
		DocumentsFormComponent,
		LoadingDotsComponent,
		ConveyancerComponent,
		TaskItemComponent,
		SwiperComponent,
		ViewersComponent,
		BugsDialogComponent,
		FAQComponent,
		RouteMeComponent,
		NewRequestDialogComponent,
		NotificationFieldComponent,
		InquiriesComponent,
		InquiryComponent,
		PayComponent,
		NewDateDialogComponent,
		EditPersonDialogComponent,
		AddPersonDialogComponent,
		NewInquiryComponent,
		LandingBuyerSellerComponent,
		LandingOtherPartyComponent,
		LandingSwitchComponent,
		InboxComponent,
		EmailComponent,
		AdminInboxComponent,
		SortEmailComponent,
		TemplatePreferencesComponent,
		OfferComponent,
		GroupTitleComponent,
		SignatureComponent,
		OfferSubmittedComponent,
		SendOfferRequestComponent,
		SafePipe,
		CCSearchesComponent,
		MaterialInformationComponent,
		MaterialFormComponent,
		EditSpaceComponent,
		EditWorksComponent,
		EditHeatComponent,
		EditParkingSpotComponent,
		EditBikeStorageComponent,
		EditBuildingIssueComponent,
		StringListPipe,
		CapFirstLettersPipe,
		MaterialDocumentsFormComponent,
		DocsViewerComponent,
		LocalPhoneNumberDirective,
		AMLComponent,
		NewAMLDialog,
		AMLpermissionsComponent,
		AMLReportComponent,
		TaskAMLComponent,
		UnsubscribeComponent,
		AdminReportsComponent,
		StrikeLineComponent,
		PropertyPostLinksComponent,
		UpdateEtaModelComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AmplifyAuthenticatorModule,
		BrowserAnimationsModule,
		FlexLayoutModule,
		MatButtonModule,
		MatIconModule,
		MatInputModule,
		MatFormFieldModule,
		MatToolbarModule,
		MatRadioModule,
		FormsModule,
		ReactiveFormsModule,
		MatMenuModule,
		MatStepperModule,
		MatSelectModule,
		MatCardModule,
		MatSidenavModule,
		MatListModule,
		HttpClientModule,
		MatCheckboxModule,
		MatDialogModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatProgressSpinnerModule,
		MatChipsModule,
		MatTooltipModule,
		MatDividerModule,
		MatDatepickerModule,
		MatNativeDateModule,
		TextFieldModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatBottomSheetModule,
		DragDropModule,
		LayoutModule,
		MatTableModule,
		MatBadgeModule,
		MatButtonToggleModule,
		MarkdownModule.forRoot(),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		BugsService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterInterceptor, multi: true },
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: {
				showError: true,
				displayDefaultIndicatorType: false,
			},
		},
		{
			provide: ErrorHandler,
			useClass: environment.production ? CwrErrorHandler : ErrorHandler,
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
	],
	bootstrap: [AppComponent],
	schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
