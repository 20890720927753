<!-- <button (click)="hideShow = !hideShow">Hide/Show</button> -->
<!-- <div  *ngIf="(taskList?.length || 0) > 0">
	<mat-progress-bar
		class="example-margin"
		color="accent"
		mode="buffer"
		[value]="progress"
		[bufferValue]="buffer">
	</mat-progress-bar>
</div> -->
<div *ngIf="(taskList?.length || 0) > 0" class="progress">
	<!-- <app-line-progress
		[completed]="completedTasks.length"
		[inProgress]="inProgressTasks.length"
		[notStarted]="notStartedTasks.length"
		[disabled]="disabledTasks.length"></app-line-progress> -->
	<!-- <pre>
			{{ completedWorkingDays | json }} {{ daysDelayed | json }} / {{
			totalWorkingDays | json
		}} {{ percentTarget }}%
		</pre> -->
	<!-- <div style="position: relative">
		<span
			class="triangle-down"
			*ngIf="totalWorkingDays > completedWorkingDays"
			[ngStyle]="{
				left: 'calc(' + percentTarget + '% - 8px)'
			}"
			[matTooltip]="targetToolTipMessage"></span>
	</div> -->
	<div style="position: relative">
		<span
			class="exchange-marker"
			*ngIf="daysTillExchange > 0"
			[ngStyle]="{
				right: percentFromExchange + '%'
			}"
			matTooltip="Contracts Are Exchanged"
			>Exchange<span
				style="
					height: 0.5rem;
					width: 0.75rem;
					margin-left: calc(0.2rem + 2px);
					margin-right: calc(-0.75rem - 2px);
					background-color: #f71481;
				"></span
		></span>
	</div>
	<div style="position: relative">
		<span
			class="completion-marker"
			*ngIf="daysTillCompletion > 0 && daysTillCompletion < totalWorkingDays"
			[ngStyle]="{
				left: percentTillCompletion + '%'
			}"
			matTooltip="Key Handover"
			><img
				style="height: 0.5rem; margin-right: 0.2rem"
				src="/assets/flag.png" />
			Completion</span
		>
	</div>
	<app-line-progress
		[completed]="completedWorkingDays || 0"
		[inProgress]="inProgressWorkingDays || 0"
		[notStarted]="notStartedWorkingDays || 0"
		[disabled]="disabledWorkingDays || 0"></app-line-progress>
</div>
<!-- <pre>
	{{ completedWorkingDays | json }}
	{{ inProgressWorkingDays | json }}
	{{ notStartedWorkingDays | json }}
	{{ disabledWorkingDays | json }}
	{{ totalWorkingDays | json }}
</pre> -->

<div style="margin-top: 1rem">
	<!-- <div>
		<div *ngIf="inProgressTasks.length > 0" matSubheader>In Progress</div>
		<div *ngFor="let task of inProgressTasks; trackBy: trackByFn">
			<app-task-item
				[task]="task"
				(markAsComplete)="markAsComplete($event, task)"
				(markAsNotStarted)="markAsNoStarted($event, task)"
				(open)="openTask(task)"></app-task-item>
		</div>

		<div *ngIf="notStartedTasks.length > 0" matSubheader>To Do</div>
		<div *ngFor="let task of notStartedTasks; trackBy: trackByFn">
			<app-task-item
				[task]="task"
				(markAsInprogress)="markAsInprogress($event, task)"
				(open)="openTask(task)"></app-task-item>
		</div>

		<div *ngIf="disabledTasks.length > 0" matSubheader>Blocked</div>
		<div *ngFor="let task of disabledTasks; trackBy: trackByFn">
			<app-task-item [task]="task" (open)="openTask(task)"></app-task-item>
		</div>

		<div *ngIf="completedTasks.length > 0" matSubheader>Completed</div>
		<div *ngFor="let task of completedTasks; trackBy: trackByFn">
			<app-task-item [task]="task" (open)="openTask(task)"></app-task-item>
		</div>

		<div *ngIf="cancelledTasks.length > 0" matSubheader>Canceled</div> -->
	<div *ngFor="let task of taskList; trackBy: trackByFn">
		<app-task-item
			[task]="task"
			[state]="state"
			[isAgent]="isAgent"
			(open)="openTask(task)"></app-task-item>
	</div>
	<!-- </div> -->
</div>
