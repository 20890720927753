<mat-card appearance="outlined">
	<mat-card-header>
		<mat-card-title>Property Post Links</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div [formGroup]="form">
			<div formArrayName="postLinks">
				<div
					*ngFor="let link of postLinks.controls; let index = index"
					class="form-row">
					<a
						href="{{ link.value }}"
						target="_blank"
						rel="noopener noreferrer"
						class="flex-grow"
						style="
							overflow: hidden;
							text-overflow: ellipsis;
							text-wrap-mode: nowrap;
						">
						{{ link.value }}
					</a>
					<button mat-icon-button color="" (click)="removePostLink(index)">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
			</div>

			<div class="form-row">
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					class="flex-grow">
					<mat-label>Add New Post Link</mat-label>
					<input matInput formControlName="newPostLink" />
					<mat-error *ngIf="form.get('newPostLink')?.hasError('required')">
						Post link is required
					</mat-error>
					<mat-error *ngIf="form.get('newPostLink')?.hasError('pattern')">
						Please enter a valid URL
					</mat-error>
				</mat-form-field>
				<button
					mat-raised-button
					style="margin-top: -1.5rem"
					color="primary"
					(click)="addPostLink()"
					[disabled]="form.get('newPostLink')?.invalid">
					Add
				</button>
			</div>
		</div>
	</mat-card-content>
</mat-card>
