import { HttpClient } from '@angular/common/http';
import {
	Component,
	ElementRef,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	ActivatedRoute,
	ChildrenOutletContexts,
	Router,
} from '@angular/router';
import { fromEvent, lastValueFrom, throttleTime } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { userType } from 'src/app/setup/httpTypes';
import { environment } from 'src/environments/environment';
import {
	trigger,
	state,
	style,
	animate,
	transition,
	query,
	animateChild,
	group,
} from '@angular/animations';
import {
	Chain,
	ChainsBackendService,
} from 'src/app/services/chains-backend.service';
import {
	BuyerSeller,
	BuyerSellersBackendService,
} from 'src/app/services/buyer-sellers-backend.service';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
	templateUrl: './chain.component.html',
	styleUrls: ['./chain.component.css'],
	animations: [
		trigger('routeAnimations', [
			transition(':decrement', [
				style({
					position: 'relative',
					display: 'block',
					// width: '100%',
					height: '100%',
				}),
				query(':enter, :leave', [
					style({
						position: 'absolute',
						//display: 'block',
						// top: 0,
						// left: 0,
						width: '100%',
						height: '100%',
						'min-height': '100%',
						//margin: 'auto',
					}),
				]),
				query(':enter', [style({ left: '-100%', opacity: 0 })]),
				query(':leave', [style({ left: '0%', opacity: 1 })]),
				group([
					query(':leave', [
						animate('300ms', style({ left: '100%', opacity: 0 })),
					]),
					query(':enter', [
						animate('300ms 100ms', style({ left: '0%', opacity: 1 })),
					]),
				]),
				//animate('2300ms ease-out', style({ translate: '100% 0%' })),
			]),
			transition(':increment', [
				style({
					position: 'relative',
					display: 'block',
					// width: '100%',
					height: '100%',
				}),
				query(':enter, :leave', [
					style({
						position: 'absolute',
						//display: 'block',
						// top: 0,
						// left: 0,
						width: '100%',
						height: '100%',
						'min-height': '100%',
					}),
				]),
				query(':enter', [style({ left: '100%', opacity: 0 })]),
				query(':leave', [style({ left: '0%', opacity: 1 })]),
				group([
					query(':leave', [
						animate('300ms', style({ left: '-100%', opacity: 0 })),
					]),
					query(':enter', [
						animate('300ms 100ms', style({ left: '0%', opacity: 1 })),
					]),
				]),
				//animate('2300ms ease-out', style({ translate: '-100% 0%' })),
			]),
		]),
	],
})
export class ChainComponent implements OnInit, OnDestroy {
	loading = true;
	chain?: Chain;
	user: any = { attributes: { email: '' } };
	chainId = '';
	$prams: any;

	@ViewChild('scrollContainer') scrollContainer!: ElementRef;

	scrollProgress = 0;

	constructor(
		public ChainsBackend: ChainsBackendService,
		public buyerSellerBackend: BuyerSellersBackendService,
		public AuthService: AuthService,
		public Router: Router,
		public route: ActivatedRoute,
		private contexts: ChildrenOutletContexts,
		private scrollService: ScrollService,
		private ngZone: NgZone
	) {}

	getRouteAnimationData() {
		if (!this.chain?.links) return 0;

		let propertyId =
			this.contexts.getContext('primary')?.route?.snapshot?.params?.[
				'propertyId'
			];

		let buyerSellerId =
			this.contexts.getContext('primary')?.route?.snapshot?.params?.[
				'buyerSellerId'
			];

		if (propertyId) {
			let index = this.chain?.links?.findIndex(
				(link: any) => link.id == propertyId
			);
			return index;
		}

		if (buyerSellerId) {
			let index = this.chain?.links?.findIndex(
				(link: any) => link.id == buyerSellerId
			);
			return index;
		}

		return undefined;
	}

	ngOnInit(): void {
		this.getUser();

		this.$prams = this.route.params.subscribe(async (params) => {
			this.chainId = params['chainId'];
			if (this.route.snapshot.queryParams['session_id']) {
				await this.buyerSellerBackend.paid({
					sessionId: this.route.snapshot.queryParams['session_id'],
				});
			}
			this.getChain();
		});
	}

	ngOnDestroy(): void {
		if (this.$prams) this.$prams.unsubscribe();
	}

	async getUser() {
		this.user = await this.AuthService.getUser();
		if (this.AuthService.spoofAs && this.AuthService.spoofAs.length > 0)
			this.user.attributes.email = this.AuthService.spoofAs;
		console.log(this.user);
	}

	async getChain() {
		let r = await this.ChainsBackend.getOne(this.chainId).catch((err) => {
			console.log(err);
			this.Router.navigate(['/error']);
			return {
				data: undefined,
				canEdit: false,
			};
		});

		if (!r.data) return;
		let _chain = r.data;

		// check start and end of chain
		if (_chain?.links?.[0]) {
			let firstLink = _chain.links[0];
			// check if start of chain is a person or a property
			if (firstLink.linkType == 'property') {
				_chain.links.unshift({
					id: 'unknown',
					linkType: 'buyerSeller',
					propertyId: firstLink.id,
					userType: userType.buyer,
					people: [],
				});
			} else if (
				firstLink.sellingPropertyState != 'noProperty' &&
				firstLink.sellingPropertyState != 'noSelection'
			) {
				_chain.links.unshift({
					id: 'unknown',
					linkType: 'property',
					buyerSellerId: firstLink.id,
					userType: userType.buyer,
					agents: [],
				});
			}

			// check if end of chain is a person or a property
			let lastLink = _chain.links[_chain.links.length - 1];
			if (lastLink.linkType == 'property') {
				_chain.links.push({
					id: 'unknown',
					linkType: 'buyerSeller',
					propertyId: lastLink.id,
					userType: userType.seller,
					people: [],
				});
			} else if (
				lastLink.buyingPropertyState != 'noProperty' &&
				lastLink.buyingPropertyState != 'noSelection'
			) {
				_chain.links.push({
					id: 'unknown',
					linkType: 'property',
					buyerSellerId: lastLink.id,
					userType: userType.seller,
					agents: [],
				});
			}
		}

		this.chain = _chain;

		this.loading = false;

		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				requestAnimationFrame(() => {
					//console.log('scrollingDiv', this.scrollContainer?.nativeElement);
					fromEvent(this.scrollContainer.nativeElement, 'scroll')
						// .pipe(
						// 	throttleTime(16) // ~60fps
						// )
						.subscribe((event: any) => {
							const element = event.target as HTMLElement;
							const scrollTop = element.scrollTop;
							const scrollHeight = element.scrollHeight - element.clientHeight;

							// Calculate scroll progress percentage
							this.scrollProgress = (scrollTop / scrollHeight) * 100;

							// Update scroll service
							this.ngZone.run(() => {
								this.scrollService.updateScrollPosition(
									scrollTop,
									element.clientHeight,
									scrollHeight
								);
							});
						});
				});
			}, 200);
		});
	}

	reverseChain(): any[] {
		let reversedChain = [...(this.chain?.links || [])];
		return reversedChain.reverse();
	}

	propsFiltered(chain: any) {
		return (
			chain?.properties?.filter((property: any) => {
				return property.partOfProp === true;
			}) || []
		);
	}

	likeUrl(link: any) {
		if (link.linkType == 'property') {
			if (link.id == 'unknown') {
				//find next link
				let nextLink: any;
				let linkIndex = this.chain?.links?.indexOf(link) || 0;
				if (linkIndex == 0) nextLink = this.chain?.links?.[1];
				else nextLink = this.chain?.links?.[linkIndex - 1];

				let inPeople = nextLink?.persons?.some((person: any) => {
					return person.email == this.user.attributes.email;
				});

				if (inPeople)
					return ['new-property', link.buyerSellerId, link.userType];
				return null;
			}
			return ['property', link.id];
		} else {
			if (link.id == 'unknown') {
				//find next link
				let nextLink: any;
				let linkIndex = this.chain?.links?.indexOf(link) || 0;
				if (linkIndex == 0) nextLink = this.chain?.links?.[1];
				else nextLink = this.chain?.links?.[linkIndex - 1];
				if (
					nextLink?.persons?.some((person: any) => {
						return person.email == this.user.attributes.email;
					})
				)
					return ['new-buyer-seller', link.propertyId, link.userType];
				return null;
			} else return ['buyer-seller', link.id];
		}

		return [link.linkType == 'property' ? 'property' : 'buyer-seller', link.id];
	}

	$update: any;
	routeActive(CopRef: any) {
		if (CopRef?.update) {
			if (this.$update?.unsubscribe) this.$update?.unsubscribe();
			this.$update = CopRef?.update.subscribe(() => {
				this.getChain();
			});
		}
	}

	routeDeactivate() {
		if (this.$update?.unsubscribe) this.$update?.unsubscribe();
	}

	propColor(property: any) {
		let color = 'green';
		if (property?.isLate) color = 'yellow';
		if (property?.issueCount > 0) color = 'yellow';
		if (property?.id == 'unknown') color = 'red';
		if (property?.isPlaceHolder) color = 'red';
		if (property?.postCode == 'No Postcode') color = 'red';
		return color;
	}

	logoURL(property: any) {
		let color = this.propColor(property);
		return `/assets/colorIcons/${color}.svg`;
	}

	me(buyerSeller: any) {
		if (buyerSeller.linkType == 'buyerSeller') {
			return buyerSeller?.persons?.some(
				(person: any) => person.email == this.user?.attributes?.email
			);
		}
		return false;
	}

	personToolTip(buyerSeller: any, i: number) {
		let tip = ``;
		if (i > 0)
			tip += `Buying ${this.chain?.links?.[i + 1]?.nickName || 'Unknown'}`;
		if (i < (this.chain?.links?.length || 0) - 1)
			tip += `${tip.length > 0 ? ' and ' : ''}Selling ${
				this.chain?.links?.[i - 1]?.nickName || 'Unknown'
			}`;

		return tip;
	}
}
