import {
	ChangeDetectionStrategy,
	Component,
	Input,
	SimpleChanges,
	type OnInit,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	Validators,
} from '@angular/forms';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import {
	PropertiesBackendService,
	Property,
} from 'src/app/services/properties-backend.service';

@Component({
	selector: 'app-property-post-links',
	templateUrl: './property-post-links.component.html',
	styleUrls: ['./property-post-links.component.css'],
})
export class PropertyPostLinksComponent implements OnInit {
	@Input() property?: Partial<Property>;
	form: any = this.fb.group({
		newPostLink: this.fb.control('', [
			Validators.required,
			Validators.pattern('https?://.+'),
		]),
		postLinks: this.fb.array([]),
	});
	constructor(
		public fb: FormBuilder,
		public propertyBackend: PropertiesBackendService,
		public confirmDialog: ConfirmDialogService
	) {}
	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['property'] && this.property) {
			this.updateFormWithPropertyLinks();
		}
	}

	get postLinks(): FormArray {
		return this.form.get('postLinks') as FormArray;
	}

	async addPostLink() {
		const newPostLinkControl = this.form.get('newPostLink');
		if (newPostLinkControl?.valid) {
			this.postLinks.push(new FormControl(newPostLinkControl.value));
			newPostLinkControl.reset();
		}
		await this.updatePropertyWithFormLinks();
	}

	async removePostLink(index: number) {
		let r = await this.confirmDialog.confirm({
			title: 'Delete Link',
			message: 'Are you sure you want to delete this link?',
			confirmText: 'Delete',
			cancelText: 'Cancel',
		});
		if (!r) return;
		this.postLinks.removeAt(index);
		await this.updatePropertyWithFormLinks();
	}

	editPostLink(index: number, link: string): void {
		this.postLinks.at(index).setValue(link);
	}

	private updateFormWithPropertyLinks(): void {
		const postLinksArray = this.property?.postLinks || [];
		this.postLinks.clear();
		postLinksArray.forEach((link) => {
			this.postLinks.push(new FormControl(link.url));
		});
	}

	async updatePropertyWithFormLinks() {
		if (this.property) {
			let newPostLinks: { url: string }[] = this.postLinks.value.map(
				(link: string) => ({ url: link })
			);

			await this.propertyBackend.patch(this.property?.id || '', {
				postLinks: newPostLinks,
			} as any);
			this.property.postLinks = newPostLinks;
		}
	}
}
